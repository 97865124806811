import ImageService from "../../../../services/ImageService";

const mercariImageSoftLimit = 12;

export const parseMercariImages = (images = []) => {
  let sumOfAllImages = 0;
  const parsedImages = images.filter(
    (image, index) => index < mercariImageSoftLimit
  );

  parsedImages.forEach((image) => {
    sumOfAllImages = sumOfAllImages + image.file.size;
  });

  sumOfAllImages = sumOfAllImages / 1024 / 1024; // to reference in MB
  let toCompress = false;
  const totalLimit = 18; // we've noticed that Mercari throws a weird error if the total size is greater than 18MB or so

  if (sumOfAllImages >= totalLimit) {
    toCompress = true;
  }

  const fileSizeLimit = totalLimit / parsedImages.length;

  const picturesBase64 = parsedImages.map((picture) => {
    return ImageService.fileToBase64(picture);
  });

  return picturesBase64;
};
