import { useCallback, useMemo } from "react";

import { concatValueWithDollarChar } from "./useBookkeepingTable";

import {
  COST_OF_REVENUES_ITEM_TITLES,
  REVENUE_LESS_RETURNS_AND_ALLOWANCES,
  SHIPPING_CHARGED_TO_BUYER,
} from "../constants";

export const useFormatBookkeepingDataForMapping = ({
  bookkeepingData,
  isCash,
}) => {
  const intervalData = bookkeepingData?.bookkeepingsByIntervals;
  const totalData = bookkeepingData?.totalBookkeeping;
  const {
    totalRevenue,
    totalCostOfRevenues,
    grossProfit,
    totalExpensesPrice,
    netProfit,
    feeReimbursementSum,
  } = bookkeepingData.totalBookkeeping || {};

  const getCostOfRevenuesItemsList = useCallback(
    (data) => {
      return isCash
        ? [
            {
              title: COST_OF_REVENUES_ITEM_TITLES.platformFees,
              value: data?.marketplaceFees,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.additionalSellingFees,
              value: data?.additionalSellingFees,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.transactionFees,
              value: data?.transactionFees,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.shippingCosts,
              value: data?.totalShippingExpenses,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.returnShippingCosts,
              value: data?.totalReturnShippingExpenses,
            },
          ]
        : [
            {
              title: COST_OF_REVENUES_ITEM_TITLES.platformFees,
              value: data?.marketplaceFees,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.additionalSellingFees,
              value: data?.additionalSellingFees,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.transactionFees,
              value: data?.transactionFees,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.shippingCosts,
              value: data?.totalShippingExpenses,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.returnShippingCosts,
              value: data?.totalReturnShippingExpenses,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.costOfGoodsSold,
              value: data?.itemCosts,
            },
            {
              title: COST_OF_REVENUES_ITEM_TITLES.feeReimbursements,
              value: data?.feeReimbursementSum,
            },
          ];
    },
    [isCash]
  );

  const formattedTotalBookkeepingDataForMapping = useMemo(
    () => ({
      revenues: totalData?.revenues
        ?.map((revenueItem) => ({
          title: revenueItem.marketplaceType,
          value: revenueItem.priceSoldSum,
        }))
        .concat([
          {
            title: SHIPPING_CHARGED_TO_BUYER,
            value: totalData?.shippingChargedToBuyer,
          },
          {
            title: REVENUE_LESS_RETURNS_AND_ALLOWANCES,
            value: totalData?.returnCosts,
          },
        ]),
      costOfRevenues: getCostOfRevenuesItemsList(totalData),
      expenses: totalData?.expenses?.map((expenseItem) => ({
        title: expenseItem.expenseCategory,
        value: concatValueWithDollarChar(expenseItem.priceSum),
      })),
      totalRevenue,
      totalCostOfRevenues,
      totalGrossProfit: grossProfit,
      totalExpenses: totalExpensesPrice,
      totalNetProfit: netProfit,
    }),
    [
      totalRevenue,
      totalCostOfRevenues,
      grossProfit,
      totalExpensesPrice,
      netProfit,
      totalData,
      getCostOfRevenuesItemsList,
    ]
  );

  const formattedIntervalBookkeepingDataForMapping = useMemo(
    () => ({
      revenues: intervalData?.map((revenuesInterval) => {
        return {
          items: revenuesInterval?.revenues
            ?.map((revenueItem) => ({
              title: revenueItem.marketplaceType,
              value: revenueItem.priceSoldSum,
            }))
            .concat([
              {
                title: SHIPPING_CHARGED_TO_BUYER,
                value: revenuesInterval?.shippingChargedToBuyer,
              },
              {
                title: REVENUE_LESS_RETURNS_AND_ALLOWANCES,
                value: revenuesInterval?.returnCosts,
              },
            ]),
          dateRange: {
            year: revenuesInterval.year,
            month: revenuesInterval?.month,
            quarter: revenuesInterval?.quarter,
          },
          intervalTotal: revenuesInterval.totalRevenue,
        };
      }),
      costOfRevenues: intervalData?.map((costOfRevenuesInterval) => {
        return {
          items: getCostOfRevenuesItemsList(costOfRevenuesInterval),
          dateRange: {
            year: costOfRevenuesInterval.year,
            month: costOfRevenuesInterval?.month,
            quarter: costOfRevenuesInterval?.quarter,
          },
          intervalTotal: costOfRevenuesInterval.totalCostOfRevenues,
        };
      }),
      grossProfit: intervalData?.map((grossProfitInterval) => ({
        intervalTotal: grossProfitInterval.grossProfit,
        dateRange: {
          year: null,
          month: null,
          quarter: null,
        },
      })),
      expenses: intervalData?.map((expensesInterval) => {
        return {
          items: expensesInterval?.expenses?.map((expenseItem) => ({
            title: expenseItem.expenseCategory,
            value: expenseItem.priceSum,
          })),
          dateRange: {
            year: expensesInterval.year,
            month: expensesInterval?.month,
            quarter: expensesInterval?.quarter,
          },
          intervalTotal: expensesInterval.totalExpensesPrice,
        };
      }),
      netProfit: intervalData?.map((netProfitInterval) => ({
        intervalTotal: netProfitInterval.netProfit,
        dateRange: {
          year: null,
          month: null,
          quarter: null,
        },
      })),
      totalRevenue,
      totalCostOfRevenues,
      totalGrossProfit: grossProfit,
      totalExpenses: totalExpensesPrice,
      totalNetProfit: netProfit,
    }),
    [
      intervalData,
      totalRevenue,
      grossProfit,
      totalCostOfRevenues,
      totalExpensesPrice,
      netProfit,
      getCostOfRevenuesItemsList,
    ]
  );
  return [
    formattedTotalBookkeepingDataForMapping,
    formattedIntervalBookkeepingDataForMapping,
  ];
};
