/* eslint-disable react/prop-types */
import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";

import ProductPreview from "../../../../../pages/drafts/components/ProductPreview";
import Checkbox from "../../../Checkbox";
import CustomBadge from "../../../CustomBadge";
import CustomButton from "../../../CustomButton";

import { useHighlight } from "../../../../hooks/useHighlight";

import styles from "./index.module.scss";
import DraftUtils from "../../../../../pages/drafts/utils/DraftUtils";
import CountEntries from "../../../../../pages/drafts/components/CountEntries";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const CardImportItem = ({
  index,
  imageSrc,
  title,
  search,
  isImported,
  price,
  isChecked = false,
  onChangeChecked,
  onStartImporting,
  valueForCSV,
  listingId,
  orderId,
  isBundle,
  dateSold,
  status,
  bundleSize,
}) => {
  const { decorateText } = useHighlight(search);

  return (
    <div className="card-import-item">
      <div className="d-flex align-items-center ">
        <Checkbox
          value={isChecked}
          onChange={() => onChangeChecked(index)}
          className="custom-green-checkbox ml-3 mr-3"
        />
        <div className={styles.imageWrapper}>
          <ProductPreview src={imageSrc} />
          {isBundle && (
            <CountEntries
              count={bundleSize}
              color="green"
              id={styles.bundleCounter}
            />
          )}
        </div>
        <TitleContainer>
          <p
            className={classNames(
              "bold-black",
              "mb-0",
              "ml-2",
              styles.ellipsis
            )}
          >
            <span {...decorateText(title)} />
          </p>
          {dateSold && (
            <p
              className={classNames(
                "bold-black",
                "mb-0",
                "ml-2",
                styles.ellipsis
              )}
            >
              <span>
                {(dateSold.getMonth() + 1).toString().padStart(2, "0")}/
                {dateSold.getDate().toString().padStart(2, "0")}/
                {dateSold.getFullYear()}
              </span>
            </p>
          )}
        </TitleContainer>
      </div>
      <div className="d-flex align-items-center card-import-item__right">
        <div className="card-import-item__right-imported">
          {isImported && <CustomBadge>Imported</CustomBadge>}
        </div>
        <p className="bold-black mb-0">{DraftUtils.formatPrice(price || 0)}</p>
        <div>
          <CustomButton
            className="filled-sm"
            onClick={() =>
              onStartImporting(
                { listingId, orderId, productOrder: { dateSold }, status },
                valueForCSV
              )
            }
          >
            Import
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

CardImportItem.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listingId: PropTypes.string,
  orderId: PropTypes.string,
  search: PropTypes.string,
  isImported: PropTypes.bool,
  onChangeChecked: PropTypes.func,
  isBundle: PropTypes.bool,
  bundleSize: PropTypes.number,
};

export default memo(CardImportItem);
