/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tooltip } from "reactstrap";
import classnames from "classnames";

import styles from "./index.module.scss";
import { ReactComponent as CopySvg } from "./images/icon-16-copy-green.svg";
import { ReactComponent as EditSvg } from "../../../../../../base/assets/edit.svg";
import { MAX_LISTING_URL_VALUE, MIN_LISTING_URL_VALUE } from "./constants";

import Divider from "../../../Divider";
import IconButton from "../../../IconButton";
import FormikInput from "../../../../../../base/components/FormikInput";
import CustomButton from "../../../../../../base/components/CustomButton";
import { useModal } from "../../../../../../base/hooks/useModal";
import { useService } from "../../../../../../base/hooks/useService";
import ProductStatusService from "../../../../../../services/ProductStatusService";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import Bugsnag from "@bugsnag/js";
import { phrases } from "../../../../../../store/phrases";

const useTooltipProps = (ref) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return {
    isOpen: tooltipOpen,
    target: ref,
    toggle,
  };
};

const Icon = ({
  link,
  isDisabled,
  IconImage,
  onClick,
  tooltipLabel = "Copy",
}) => {
  const ref = useRef();
  const [isCopied, updateIsCopied] = useState(false);

  return (
    <>
      <div
        onAnimationEnd={() => updateIsCopied(false)}
        className={classnames(styles.labelCopied, {
          [styles.labelIsCopiedShow]: isCopied,
        })}
      >
        <span>URL is copied</span>
      </div>
      <IconButton
        ref={ref}
        className={classnames(styles.copyBtn, {
          [styles.disabledCopyBtn]: isDisabled,
        })}
        onClick={(event) => {
          event.preventDefault();
          if (isDisabled) return;

          if (!onClick) {
            navigator.clipboard.writeText(link);
            updateIsCopied(true);
          } else {
            onClick();
          }
        }}
      >
        <IconImage className={styles.copySvg} ref={ref} />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        {tooltipLabel}
      </Tooltip>
    </>
  );
};

const ListingUrl = ({
  values,
  postedInfo: { isPosted, link } = {},
  setFieldValue,
  isDisabledCopyBtn,
  description = "",
  marketplaceType = null,
}) => {
  const {
    Modal,
    isOpen,
    open,
    close,
    getModalProps: { onClose },
  } = useModal();
  const [listingUrl, setListingUrl] = useState("");
  const productStatusService = useService(ProductStatusService);
  const {
    params: { draftId },
  } = useRouteMatch();

  useEffect(() => {
    if (isPosted && link) {
      setFieldValue("link", link);
      setListingUrl(link);
    }
  }, [link, setFieldValue]);

  if (!isPosted) return null;

  const openUrlModal = () => {
    open();
  };

  const processListingUrl = () => {
    if (listingUrl.trim() === "") return;

    productStatusService
      .updateListingUrl({
        id: draftId,
        listingUrl,
        marketplaceType,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  };

  return (
    <>
      <h3 className="bold-black mb-4 mt-4">Listing URL</h3>
      <Divider />
      <Row className="mb-5">
        <Col md="6">
          <div className={styles.listingUrlInputContainer}>
            <FormikInput
              name="link"
              onChange={({ target: { value } }) => {
                setFieldValue("link", value);
              }}
              containerClassName={styles.formikInputContainer}
              min={MIN_LISTING_URL_VALUE}
              max={MAX_LISTING_URL_VALUE}
              maxLength={MAX_LISTING_URL_VALUE}
              placeholder={"Enter listing URL"}
            />
            <Icon
              IconImage={EditSvg}
              link={values?.link || ""}
              isDisabled={isDisabledCopyBtn}
              tooltipLabel="Update listing URL"
              onClick={openUrlModal}
            />
            <Icon
              IconImage={CopySvg}
              link={values?.link || ""}
              isDisabled={isDisabledCopyBtn}
            />
            <Modal isOpen={isOpen} onClose={close}>
              <Modal.Header onClose={close}>Update listing URL</Modal.Header>
              <Modal.Body>
                <p className="text-gray-gomi">{phrases.urlUpdateWarning}</p>
                <FormikInput
                  name="link"
                  onChange={({ target: { value } }) => {
                    setListingUrl(value);
                  }}
                  containerClassName={styles.formikInputContainer}
                  min={MIN_LISTING_URL_VALUE}
                  max={MAX_LISTING_URL_VALUE}
                  maxLength={MAX_LISTING_URL_VALUE}
                  placeholder={"Enter listing URL"}
                />
              </Modal.Body>
              <Modal.Footer>
                <CustomButton
                  onClick={processListingUrl}
                  className="filled-sm w-80"
                >
                  Update
                </CustomButton>
              </Modal.Footer>
            </Modal>
          </div>
          {description && (
            <p className={"font-size-10 text-gray-gomi mb-1 mt-2"}>
              {description}
            </p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ListingUrl;
