import { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import Bugsnag from "@bugsnag/js";

import useUploadImagesToS3 from "./useUploadImagesS3";
import { useGlobalLoading } from "../../../base/contexts/LoadingContext";
import { phrases } from "../../../store/phrases";

class ImageData {
  constructor(file) {
    this.file = file;
    this.preview = URL.createObjectURL(file);
    this.id = null;
  }
}

export const useDraftImages = (maxImagesCount) => {
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [allSelectedImages, setAllSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [field, , helpers] = useField({
    name: "files",
  });
  const { setValue } = helpers;
  const selectedImages = field.value;
  const isOpenEditModal = !!selectedImage;
  const { setLoading: setLoadingWithMessage } = useGlobalLoading();
  const uploadFiles = useUploadImagesToS3();

  const setSelectedImages = useCallback(
    (images) => {
      return setValue(images);
    },
    [selectedImages, setValue, allSelectedImages]
  );

  const onOpenUploadModal = useCallback(() => {
    setIsOpenUploadModal(true);
  }, [setIsOpenUploadModal]);

  const onCloseUploadModal = useCallback(() => {
    setIsOpenUploadModal(false);
  }, [setIsOpenUploadModal]);

  const onSave = useCallback(() => {
    onCloseUploadModal();
  }, [onCloseUploadModal]);

  const imageToImageData = async (url, id, path) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = url;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Invalid image blob"));
            return;
          }

          const formattedBlob = new Blob([blob], { type: "image/png" });
          const preview = URL.createObjectURL(formattedBlob);

          resolve({
            file: {
              path,
            },
            preview,
            id,
          });
        }, "image/png");
      };

      img.onerror = (error) => {
        reject(new Error(`Failed to load image: ${error.message}`));
      };
    });
  };

  const setImages = useCallback(
    async (files) => {
      if (!files || files.length === 0) return;

      if (files.length <= maxImagesCount - 1) {
        setLoading(true);
        await uploadFiles(files)
          .then(async (uploadData) => {
            const { data } = uploadData;
            const allImageDataPromises = data.map((uploadedFile, i) => {
              const {
                file: { id, link },
              } = uploadedFile;

              return imageToImageData(link, id, files[i].path);
            });

            const allImageData = await Promise.all(allImageDataPromises);
            const imagesCopy = allSelectedImages.slice().concat(allImageData);
            setAllSelectedImages(imagesCopy);
          })
          .catch((e) => Bugsnag.notify(e))
          .finally(() => {
            setLoading(false);
            onCloseUploadModal();
          });
      } else {
        setSelectedImages(allSelectedImages);
      }
    },

    [onCloseUploadModal, allSelectedImages]
  );

  const onEditImage = useCallback(
    (index) => {
      setSelectedImage({
        ...selectedImages[index].file,
        preview: selectedImages[index].preview,
        index,
      });
    },
    [setSelectedImage, selectedImages]
  );

  const onCropImage = useCallback(
    (croppedImage) => {
      const selectedImagesArray = [...selectedImages];
      const imgData = new ImageData(croppedImage);
      setLoadingWithMessage(true, phrases.processingImage);

      uploadFiles([croppedImage])
        .then(async (response) => {
          const { data } = response;
          const image = data[0];
          const formattedImage = await imageToImageData(
            image.file.link,
            image.file.id,
            image.file.name
          );

          selectedImagesArray[selectedImage?.index] = formattedImage;
          setSelectedImages(selectedImagesArray);
        })
        .catch((e) => {
          Bugsnag.notify(e);
        })
        .finally(() => {
          setLoadingWithMessage(false);
          onCloseEditModal();
        });
    },

    [setSelectedImages, setSelectedImage, selectedImage, uploadFiles]
  );

  const onCloseEditModal = useCallback(() => {
    setSelectedImage(null);
  }, [setSelectedImage]);

  const onDeleteImage = useCallback(
    (index) => {
      const selectedImagesArray = [...selectedImages];
      selectedImagesArray.splice(index, 1);
      setSelectedImages(selectedImagesArray.slice());
    },
    [setSelectedImages, selectedImages]
  );

  useEffect(() => {
    setSelectedImages(allSelectedImages);
  }, [JSON.stringify(allSelectedImages)]);

  useEffect(() => {
    if (field.value?.length !== 0) {
      setSelectedImages(field.value);
      setAllSelectedImages(field.value);
    }
  }, [field.value]);

  const clearAll = useCallback(() => {
    setSelectedImages([]);
  }, []);

  return {
    selectedImages,
    isOpenUploadModal,
    isOpenEditModal,
    onOpenUploadModal,
    onCloseUploadModal,
    onSave,
    setImages,
    onEditImage,
    selectedImage,
    onCropImage,
    onCloseEditModal,
    onDeleteImage,
    setSelectedImages,
    clearAll,
    field,
    setValue,
    loading,
  };
};

export const useUploadFiles = () => {
  const uploadImagesToS3 = useUploadImagesToS3();

  return useCallback(
    (files) => {
      return uploadImagesToS3(
        files.map(({ file }) => file),
        files
      );
    },
    [uploadImagesToS3]
  );
};
