import imageCompression from "browser-image-compression";
import Bugsnag from "@bugsnag/js";

import Http from "./Http";

class ImageService extends Http {
  static $displayName = "ImageService";

  resizeLoadedImages(ids) {
    return this.patch("/files/images/resize", ids);
  }

  static async fileToBase64(file, toCompress = false, fileSizeLimit = 1.5) {
    const options = {
      maxSizeMB: fileSizeLimit,
      useWebWorker: true,
      alwaysKeepResolution: false,
    };

    let modifiedFile;

    if (
      file &&
      toCompress === true &&
      file.size / 1024 / 1024 >= fileSizeLimit
    ) {
      try {
        Bugsnag.leaveBreadcrumb("Compressing image", {
          file,
        });
        const compressedFile = await imageCompression(file, options);
        modifiedFile = compressedFile;
      } catch (e) {
        Bugsnag.notify(e);
        modifiedFile = file;
      }
    } else {
      modifiedFile = file;
    }

    return new Promise(async (resolve, reject) => {
      Bugsnag.leaveBreadcrumb("Converting image to base64", {
        file: modifiedFile,
      });

      let rawBlob = null;

      if (modifiedFile.preview.indexOf("blob:") === 0) {
        const rawBlobResponse = await fetch(modifiedFile.preview);
        rawBlob = await rawBlobResponse.blob();
      } else {
        rawBlob = modifiedFile.file;
      }

      const reader = new FileReader();
      reader.onloadend = function () {
        const fragments = reader.result.split(";base64");
        const type = fragments[0];
        const base64Data = reader.result.replace(type, `data:image/png`);
        resolve(base64Data);
      };
      reader.readAsDataURL(rawBlob);
      reader.onerror = reject;
    });
  }
}

export default ImageService;
