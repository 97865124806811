import Bugsnag from "@bugsnag/js";

import Http from "./Http";
import PoshmarkExtension from "./PoshmarkExtension";
import { createPoshmarkModelForRequest } from "../pages/drafts/helpers/Poshmark/createPoshmarkModelForRequest";
import { convertSpecPoshmarkFieldsForSaving } from "../pages/drafts/helpers/Poshmark/convertSpecPoshmarkFieldsForSaving";

class PoshmarkService extends Http {
  static $displayName = "PoshmarkService";

  postProduct(id, listingId) {
    return this.patch(`/products/${id}/poshmark`, listingId);
  }

  async relistPoshmarkInventory(
    id,
    marketplace,
    listingId,
    fetchedData,
    formValues
  ) {
    const poshmarkExtension = new PoshmarkExtension();

    const { convertedData, picturesBase64 } = createPoshmarkModelForRequest(
      fetchedData,
      formValues
    );

    try {
      const mockData = structuredClone(convertedData);
      mockData.inventory = {
        ...mockData.inventory,
        size_quantities: [
          {
            size_id: "fearn",
            size_obj: {
              display: "fearn",
              display_with_size_set: "fearn",
              id: "fearn",
              size_systems: "us",
            },
          },
        ],
      };

      await poshmarkExtension.editInventory(listingId, [], mockData);
      await this.delayPromise(5000);
    } catch (e) {
      Bugsnag.notify(e, {
        id: listingId,
      });
    }

    return poshmarkExtension
      .deleteInventory(listingId)
      .then(() => {
        return poshmarkExtension.createDraft();
      })
      .then((data) => {
        const poshmarkId = data.id;
        return Promise.all(picturesBase64).then((pictures) => {
          return poshmarkExtension
            .postDraft(poshmarkId, pictures, convertedData)
            .then((resp) => {
              const { result } = resp;
              return { listingId: result };
            });
        });
      })
      .catch((e) => {
        Bugsnag.notify(e, (event) => {
          event.addMetadata("PoshmarkRelistError", { id: listingId });
        });
      });
  }

  delayPromise(t) {
    return new Promise(function (resolve) {
      setTimeout(resolve, t);
    });
  }

  delistPoshmarkInventory(id, marketplace, listingId, fetchedData, formValues) {
    const poshmarkExtension = new PoshmarkExtension();
    const { productPoshmarkSpecificFields } = fetchedData;

    const convertedData = convertSpecPoshmarkFieldsForSaving(
      productPoshmarkSpecificFields,
      formValues
    );

    const sizeProperty = [
      {
        size_id: "fearn",
        size_obj: {
          display: "fearn",
          dispaly_with_size_set: "fearn",
          id: "fearn",
          size_system: "us",
        },
      },
    ];

    convertedData.inventory.size_quantities = sizeProperty.slice();

    return Promise.all([
      poshmarkExtension.editInventory(
        productPoshmarkSpecificFields.listingId,
        [],
        convertedData
      ),
      this.delayPromise(5000), // delaying this because poshmark takes some time on their end to cancel any offers on items
      // we can't delete an item if there are existing offers
    ])
      .then(() => {
        return poshmarkExtension.deleteInventory(listingId);
      })
      .catch((err) => {
        Bugsnag.notify(err);

        return Promise.reject(err);
      });
  }

  updateLastSyncedDate(newDate) {
    return this.put("/poshmark-accounts/lastSyncedAt", {
      lastSyncedAt: newDate,
    });
  }

  savePoshmarkMarketplaceAccount(data) {
    return this.post("/poshmark-accounts", data);
  }
}

export default PoshmarkService;
