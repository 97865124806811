import React, { useEffect, useState, useCallback, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import classnames from "classnames";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../base/components/CustomButton";
import PageTitleBreadcrumb from "../../../../base/components/PageTitleBreadcrumb";
import { useService } from "../../../../base/hooks/useService";
import { SOLD_GROUP_LINKS } from "../../config";
import SoldService from "../../../../services/SoldService";

import TableItem from "./components/TableItem";
import styles from "./index.module.scss";
import { MARKETPLACE_SPECIFIC_FIELDS } from "../../constants";
import {
  ADDITIONAL_SELLING_FEES_TOOLTIP_TEXT,
  MARKETPLACE_TYPES as MARKETPLACE_TYPES_IDS,
  PAYMENTS_METHODS,
  PAYMENT_METHODS_LABELS,
} from "../../../drafts/hooks/useProductStatus/components/SoldModal/constants";
import { FormatPrice, getFormattedDate } from "../../../drafts/helpers/format";
import { Formik } from "formik";
import InfoPriceItem from "../../../drafts/hooks/useProductStatus/components/SoldModal/components/InfoPriceItem";
import { useLoading } from "../../../../base/hooks/useLoading";
import { toNumber } from "../../helpers";
import Divider from "../../../drafts/components/Divider";
import FormikSingleSelect from "../../../../base/components/FormikSingleSelect";
import {
  MARKETPLACES_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
} from "../../../drafts/hooks/useProductStatus/components/SoldModal/constants";
import { UseEffectInsideFormik } from "../../../drafts/hooks/useProductStatus/components/SoldModal/index";
import { MARKETPLACE_TYPE } from "../../../inventory/constants/importInventory";
import { SOLD_PREV_PATH } from "../../../drafts/constants";
import { usePrevLocation } from "../../../../base/hooks/usePrevLocation";
import { MAX_BUYER_LENGTH } from "../../../drafts/hooks/useProductStatus/components/SoldModal/form";
import ToolTip from "../../../../base/components/ToolTip/ToolTip";
import { parseFromHtml } from "../../../../base/helpers/parseFromHtml";
import { phrases } from "../../../../store/phrases";

const SOLD_BREADCRUMBS = [
  { title: "Sold inventory", link: SOLD_GROUP_LINKS.BASE },
  { title: "Sold details", link: "#" },
];

const SoldDetails = () => {
  const {
    params: { draftId },
  } = useRouteMatch();
  const [, { registerPromise }] = useLoading();

  const [fetchedData, setFetchedData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);

  const formRef = useRef();

  const onOpenEditMode = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  const onCloseEditMode = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  /**
   * @type {SoldService}
   */
  const soldService = useService(SoldService);

  const onSaveChanges = useCallback(() => {
    const values = formRef.current.values;
    const data = {
      priceSold: toNumber(values.priceSold),
      itemCost: toNumber(values.costOfGood),
      marketplaceFees: toNumber(values.marketplaceFees),
      shippingExpenses: toNumber(values.shippingCost),
      shippingChargedToBuyer: toNumber(values.shippingChargedToBuyer),
      additionalSellingFees: toNumber(values.additionalSellingFees),
      marketplaceType: values?.marketplaceType,
      paymentMethod:
        values.marketplaceType !== MARKETPLACE_TYPES_IDS.etsy
          ? null
          : values.paymentMethod,
      transactionFees: toNumber(values?.transactionFees),
      buyer: values.buyer,
    };

    const formattedDataList = Object.entries(data).filter(
      (field) => !!field[1]
    );
    const formattedDataObject = Object.fromEntries(formattedDataList);

    registerPromise(soldService.updateSold(draftId, formattedDataObject))
      .then(({ data }) => {
        setFetchedData(data);
        onCloseEditMode();
      })
      .catch((e) => Bugsnag.notify(e));
  }, [draftId, soldService, formRef, registerPromise, onCloseEditMode]);

  useEffect(() => {
    registerPromise(soldService.getProduct(draftId))
      .then(({ data }) => {
        setFetchedData(data);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [draftId, registerPromise, soldService]);

  const { prevPath } = usePrevLocation(SOLD_PREV_PATH);

  const description =
    fetchedData?.product?.description ||
    fetchedData?.product?.[
      MARKETPLACE_SPECIFIC_FIELDS[fetchedData?.marketplaceType]
    ]?.description;

  const getDaysTillSold = (listedAt, soldAt) => {
    if (!listedAt || !soldAt) return "-";

    const listedAtDate = new Date(listedAt);
    const soldAtDate = new Date(soldAt);

    const timeDifference = soldAtDate.getTime() - listedAtDate.getTime();
    const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));

    return `${daysDifference} days`;
  };

  const getProfit = (
    soldPrice,
    shippingCharges,
    costOfItem,
    marketplaceFee,
    sellingFee,
    transactionFee,
    shippingCost
  ) => {
    return (
      soldPrice +
      shippingCharges -
      costOfItem -
      marketplaceFee -
      sellingFee -
      transactionFee -
      shippingCost
    );
  };

  return (
    <>
      <PageTitleBreadcrumb
        title="SOLD DETAILS"
        breadcrumbItems={SOLD_BREADCRUMBS}
        onBackPath={prevPath.current || SOLD_GROUP_LINKS.BASE}
      />
      <Card className="mt-3">
        <CardBody>
          <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
              {fetchedData?.product?.files && (
                <img
                  src={fetchedData?.product?.files[0]?.link}
                  className={styles.image}
                  alt="product image"
                />
              )}
            </div>
            <div className={styles.mainContentWrapper}>
              <span className={styles.title}>
                {fetchedData?.product?.title ||
                  fetchedData?.product?.[
                    MARKETPLACE_SPECIFIC_FIELDS[fetchedData?.marketplaceType]
                  ]?.title ||
                  "-"}
              </span>
              <span className={styles.sybTitle}>
                {fetchedData?.product?.categoryName ||
                  fetchedData?.product?.[
                    MARKETPLACE_SPECIFIC_FIELDS[fetchedData?.marketplaceType]
                  ]?.categoryName ||
                  "-"}
              </span>
              <span className={styles.description}>
                <>
                  {description ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parseFromHtml(description).replace(
                          /\n/g,
                          `<br/>`
                        ),
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </>
              </span>
              <div className={styles.tableWrapper}>
                <TableItem
                  title="SKU"
                  value={
                    fetchedData?.product?.sku ||
                    fetchedData?.product?.[
                      MARKETPLACE_SPECIFIC_FIELDS[fetchedData?.marketplaceType]
                    ]?.sku ||
                    "-"
                  }
                />
                <TableItem
                  title="Sold date"
                  value={getFormattedDate(fetchedData?.dateSold)}
                />
                {/*<TableItem title="Buyer" value={fetchedData?.buyer} />*/}
                <TableItem
                  title="Brand"
                  value={
                    fetchedData?.product?.brand?.name ||
                    fetchedData?.product?.[
                      MARKETPLACE_SPECIFIC_FIELDS[fetchedData?.marketplaceType]
                    ]?.brandName
                  }
                />

                <TableItem
                  title="Days till sold"
                  value={getDaysTillSold(
                    fetchedData?.product?.firstListedAt,
                    fetchedData?.dateSold
                  )}
                />
              </div>
              <Divider />
              {isEditMode ? (
                <Formik
                  initialValues={{
                    priceSold: fetchedData?.priceSold || 0,
                    marketplaceFees: fetchedData?.marketplaceFees || 0,
                    costOfGood: fetchedData?.itemCost || 0,
                    shippingCost: fetchedData?.shippingExpenses || 0,
                    shippingChargedToBuyer:
                      fetchedData?.shippingChargedToBuyer || 0,
                    additionalSellingFees:
                      fetchedData?.additionalSellingFees || 0,
                    marketplaceType: fetchedData?.marketplaceType,
                    paymentMethod:
                      fetchedData?.paymentMethod || PAYMENTS_METHODS.etsy,
                    transactionFees: fetchedData?.transactionFees || 0,
                    buyer: fetchedData?.buyer || "",
                  }}
                  onSubmit={onSaveChanges}
                  validateOnChange={true}
                  innerRef={formRef}
                >
                  {({ values, setFieldValue, handleSubmit }) => {
                    const isShowEmptyPriceSoldWarning =
                      !values.priceSold &&
                      (values.marketplaceType === MARKETPLACE_TYPES_IDS.etsy ||
                        values.marketplaceType ===
                          MARKETPLACE_TYPES_IDS.mercari);

                    return (
                      <form onSubmit={handleSubmit}>
                        <UseEffectInsideFormik
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                        {isShowEmptyPriceSoldWarning && (
                          <span className={styles.emptyPriceSoldWarning}>
                            Enter sold price first to calculate transaction fees
                          </span>
                        )}
                        <div className={styles.mainPriceInfo}>
                          <InfoPriceItem
                            title="Price sold"
                            value={values.priceSold}
                            name="priceSold"
                            onChange={(value) => {
                              setFieldValue("priceSold", value);
                            }}
                            placeholder="0"
                          />
                          <InfoPriceItem
                            title="Additional selling fees"
                            value={values.additionalSellingFees}
                            name="additionalSellingFees"
                            onChange={(value) => {
                              setFieldValue("additionalSellingFees", value);
                            }}
                            placeholder="0"
                            toolTip={
                              <ToolTip
                                classNames="mr-2"
                                text={ADDITIONAL_SELLING_FEES_TOOLTIP_TEXT}
                              />
                            }
                          />
                          <InfoPriceItem
                            title="Cost of good"
                            value={values.costOfGood}
                            name="costOfGood"
                            onChange={(value) => {
                              setFieldValue("costOfGood", value);
                            }}
                            placeholder="0"
                          />
                          <InfoPriceItem
                            title="Transaction fees"
                            value={values.transactionFees}
                            name="transactionFees"
                            onChange={(value) => {
                              setFieldValue("transactionFees", value);
                            }}
                            placeholder="0"
                          />
                          <FormikSingleSelect
                            name="marketplaceType"
                            label="Marketplace"
                            options={MARKETPLACES_OPTIONS}
                            containerClassName={styles.dropdownWrapper}
                            placeholder="Choose marketplace"
                          />
                          <InfoPriceItem
                            title="Shipping cost"
                            value={values.shippingCost}
                            name="shippingCost"
                            onChange={(value) => {
                              setFieldValue("shippingCost", value);
                            }}
                            placeholder="0"
                          />
                          <InfoPriceItem
                            title="Marketplace fees"
                            value={values.marketplaceFees}
                            name="marketplaceFees"
                            onChange={(value) => {
                              setFieldValue("marketplaceFees", value);
                            }}
                            placeholder="0"
                          />
                          <InfoPriceItem
                            title="Shipping charged to buyer"
                            value={values.shippingChargedToBuyer}
                            name="shippingChargedToBuyer"
                            onChange={(value) => {
                              setFieldValue("shippingChargedToBuyer", value);
                            }}
                            placeholder="0"
                            toolTip={
                              <ToolTip
                                classNames="mr-2"
                                text={phrases.shippingChargedToBuyerSoldTooltip}
                              />
                            }
                          />
                          {values.marketplaceType ===
                            MARKETPLACE_TYPES_IDS.etsy && (
                            <FormikSingleSelect
                              name="paymentMethod"
                              label="Payment method"
                              options={PAYMENT_METHOD_OPTIONS}
                              containerClassName={styles.dropdownWrapper}
                              placeholder="Choose payment method"
                            />
                          )}
                          <InfoPriceItem
                            title="Buyer"
                            value={values.buyer}
                            name="buyer"
                            onChange={(value) => {
                              setFieldValue("buyer", value);
                            }}
                            placeholder="Enter buyer"
                            isOnlyInput
                            isPrice={false}
                            maxLength={MAX_BUYER_LENGTH}
                          />
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              ) : (
                <>
                  <Row className={styles.tableDetailWrapper}>
                    <Col md={5}>
                      <TableItem
                        title="Price sold"
                        value={FormatPrice(fetchedData?.priceSold)}
                      />
                      {fetchedData?.youMade && (
                        <TableItem
                          title="You made"
                          value={FormatPrice(fetchedData?.youMade)}
                        />
                      )}
                      <TableItem
                        title="Cost of good"
                        value={FormatPrice(fetchedData?.itemCost)}
                      />
                      <TableItem
                        title="Marketplace"
                        value={MARKETPLACE_TYPE[fetchedData?.marketplaceType]}
                      />
                      <TableItem
                        title="Marketplace fees"
                        value={FormatPrice(fetchedData?.marketplaceFees)}
                      />
                      {fetchedData?.marketplaceType ===
                        MARKETPLACE_TYPES_IDS.etsy && (
                        <TableItem
                          title="Payment method"
                          value={
                            PAYMENT_METHODS_LABELS[fetchedData?.paymentMethod]
                          }
                        />
                      )}
                      <TableItem title="Buyer" value={fetchedData?.buyer} />
                    </Col>
                    <Col md={5}>
                      <TableItem
                        title="Additional selling fees"
                        value={FormatPrice(fetchedData?.additionalSellingFees)}
                        toolTip={
                          <ToolTip
                            classNames="mr-2"
                            text={ADDITIONAL_SELLING_FEES_TOOLTIP_TEXT}
                          />
                        }
                      />
                      <TableItem
                        title="Transaction fees"
                        value={FormatPrice(fetchedData?.transactionFees)}
                      />
                      <TableItem
                        title="Shipping cost"
                        value={FormatPrice(fetchedData?.shippingExpenses)}
                      />
                      <TableItem
                        title="Shipping charged to buyer"
                        value={FormatPrice(fetchedData?.shippingChargedToBuyer)}
                        toolTip={
                          <ToolTip
                            classNames="mr-2"
                            text={phrases.shippingChargedToBuyerSoldTooltip}
                          />
                        }
                      />
                      <TableItem
                        title="Profit"
                        value={`$${parseFloat(
                          getProfit(
                            fetchedData?.priceSold || 0,
                            fetchedData?.shippingChargedToBuyer || 0,
                            fetchedData?.itemCost || 0,
                            fetchedData?.marketplaceFees || 0,
                            fetchedData?.additionalSellingFees || 0,
                            fetchedData?.transactionFees || 0,
                            fetchedData?.shippingExpenses || 0
                          )
                        ).toFixed(2)}`}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
          {isEditMode ? (
            <div className={styles.saveBtnWrapper}>
              <span className={styles.warning}>
                Editing these fields may affect your Analytics and/or
                Bookkeeping.
              </span>
              <CustomButton
                className={classnames("filled-primary", styles.saveBtn)}
                type="button"
                onClick={onSaveChanges}
              >
                Save
              </CustomButton>
            </div>
          ) : (
            <CustomButton
              className={classnames(
                "filled-primary button-content",
                styles.editBtn
              )}
              onClick={onOpenEditMode}
            >
              <i className="bx bxs-edit-alt font-size-16 mr-2" />
              Edit
            </CustomButton>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default SoldDetails;
