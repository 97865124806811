import { useLocation } from "react-router-dom";
import { INVENTORY_STATUSES } from "../../../../../../inventory/pages/EditInventory/constants";
import { INVENTORY_TABS } from "../constants";
import { MARKETPLACE_LISTING_URL } from "../../../../../../inventory/constants";

export const useListingUrl = ({ fetchedData }) => {
  const { pathname } = useLocation();
  const marketplaceType = pathname.substring(
    pathname.lastIndexOf("/") + 1,
    pathname.length
  );

  const marketplaceObjectName = INVENTORY_TABS.find(
    (tab) => tab.type === marketplaceType
  );

  return {
    isPosted:
      fetchedData?.[marketplaceObjectName.objectName]?.status ===
      INVENTORY_STATUSES.listed,
    link:
      fetchedData?.[marketplaceObjectName.objectName]?.link ||
      `${MARKETPLACE_LISTING_URL[marketplaceObjectName.objectName]}${
        fetchedData?.[marketplaceObjectName.objectName]?.listingId
      }`,
  };
};
