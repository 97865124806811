/* eslint-disable react/prop-types */
import { EtsyProductImages } from "../../hooks/useProductImages";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import FormPrimaryColor from "../DraftBasicFormInner/partials/FormPrimaryColor";
import {
  ETSY_BRAND_NAME,
  ETSY_PRIMARY_COLORS,
  ETSY_RENEWAL_OPTION_AUTOMATIC,
  ETSY_RENEWAL_OPTION_MANUAL,
  ETSY_WHAT_IS_IT_OPTIONS,
  ETSY_WHEN_WAS_IT_MADE_OPTIONS,
  ETSY_WHO_MADE_IT_OPTIONS,
  MAX_ETSY_TITLE_LENGTH,
} from "../../const/eatsy";
import FormCategory, {
  applySuggested,
  useActionsRef,
} from "../DraftBasicFormInner/partials/FormCategory";
import RenderCustomFields from "./partials/RenderCustomFields";
import { mapEtsyProperties } from "../../helpers/customProperties";
import ShippingTemplate from "./partials/ShippingTemplate";
import FormikGroupAddon from "../GroupAddon";
import FormikSingleSelect from "../../../../base/components/FormikSingleSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useService } from "../../../../base/hooks/useService";
import EtsyService from "../../../../services/EtsyService";
import { listCategoriesToOptions } from "../../helpers/listToOptions";
import { useLoading } from "../../../../base/hooks/useLoading";
import ListingUrl from "../DraftEbayFormInner/partials/ListingUrl";
import TitleForBlock from "../TitleForBlock";
import WrapperElementBlock from "../WrapperElementBlock";
import FormikInput from "../../../../base/components/FormikInput";
import FormikTextarea from "../../../../base/components/FormikTextarea";
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_TITLE_LENGTH,
} from "../../../../validation/lengthConstants";
import { COUNT_OF_ROWS_STR } from "../../const/poshmark";
import FormTags from "../DraftBasicFormInner/partials/FormTags";
import {
  MAX_LENGTH_SKU,
  STEP_BY_HUNDREDTH,
} from "../../../../base/constants/forms";
import FormikMultiSelect from "../../../../base/components/FormikMultiSelect";
import FormikCheckbox from "../../../../base/components/FormikCheckbox";
import { usePriceValidation } from "../../../../base/hooks/usePriceValidation";
import { getCategoryShortName } from "../../helpers/getCategoryShortName";
import { useField } from "formik";
import { getEtsyDimensionsLabel } from "../../helpers/Etsy/getEtsyDimensionsLabel";
import { MARKETPLACES } from "../../constants";

const Categories = ({ categoryId, suggested, isLoading }) => {
  const [loading, { registerPromise }] = useLoading();
  const [categoryInitialProps, updateCategoryProps] = useState({
    onInitBreadcrumbs: () => [],
    initialValue: undefined,
  });

  const actions = useActionsRef();

  /**
   *
   * @type {EtsyService}
   */
  const etsy = useService(EtsyService);

  useEffect(() => {
    // prefetch
    registerPromise(
      etsy
        .getCategories()
        .then(() => {
          const map = etsy.getCachedCategories();

          function findAllParentCategories(parentId, collection) {
            const category = map[parentId];

            if (!category) return collection;

            collection.unshift(category);

            return findAllParentCategories(category?.parent_id, collection);
          }

          const parentId = map[categoryId]?.parent_id;

          updateCategoryProps({
            onInitBreadcrumbs: () => findAllParentCategories(parentId, []),
            initialValue: map[categoryId],
          });
        })
        .catch((e) => Bugsnag.notify(e))
    );
  }, [categoryId, etsy, registerPromise]);

  const getCategoryOptions = useCallback(
    (query, parentId) =>
      etsy.getCategories(query, parentId).then(listCategoriesToOptions),
    [etsy]
  );

  // Suggested
  useEffect(() => {
    if (categoryId || !suggested) return;

    getCategoryOptions(suggested)
      .then((options) => {
        if (!options.length) return;
        applySuggested(actions.current, suggested);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [suggested, getCategoryOptions, categoryId, actions]);

  useEffect(() => {
    isLoading(loading);
  }, [loading]);

  return (
    <WrapperElementBlock
      label={"Category*"}
      loading={loading}
      description={
        "Type a two- or three word description of your item to get category suggestions that will help more shoppers find it"
      }
    >
      <FormCategory
        getCategoryOptions={getCategoryOptions}
        onUpdatePlaceholder={() => ""}
        placeholder="Select category"
        isHideLabel
        key={loading.toString()}
        {...categoryInitialProps}
        innerRef={actions}
      />

      <Col md="12">
        <div className="mt-4 mb-3">
          <h3 className="bold-black mb-1">{"Item specifics"}</h3>
        </div>

        {!loading && (
          <RenderCustomFields
            categoryId={categoryId}
            mapFieldData={mapEtsyProperties}
          />
        )}
      </Col>
    </WrapperElementBlock>
  );
};

const DraftEtsyFormInner = ({
  values,
  setFieldValue,
  postedInfo,
  formRef,
  marketplaceData,
  suggestedFields,
  isLoading,
}) => {
  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  useEffect(() => {
    if (values.renewalOptionsAutomatic) {
      setFieldValue("renewalOptions", ETSY_RENEWAL_OPTION_AUTOMATIC);
      setFieldValue("renewalOptionsManual", false);
    }
  }, [setFieldValue, values.renewalOptionsAutomatic]);

  useEffect(() => {
    if (values.renewalOptionsManual) {
      setFieldValue("renewalOptions", ETSY_RENEWAL_OPTION_MANUAL);
      setFieldValue("renewalOptionsAutomatic", false);
    }
  }, [setFieldValue, values.renewalOptionsManual]);

  useEffect(() => {
    if (values.renewalOptions === ETSY_RENEWAL_OPTION_AUTOMATIC) {
      setFieldValue("renewalOptionsAutomatic", true);
    } else {
      setFieldValue("renewalOptionsManual", true);
    }
  }, [values.renewalOptions, setFieldValue]);

  useEffect(() => {
    setFieldValue("brandName", ETSY_BRAND_NAME[values.whoMadeIt] || "");
  }, [values.whoMadeIt, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      "categoryShortName",
      getCategoryShortName(values.categoryName)
    );
  }, [setFieldValue, values.categoryName]);

  const [{ value: titleValue }, , { setTouched }] = useField({ name: "title" });

  useEffect(() => {
    if (titleValue?.length <= MAX_ETSY_TITLE_LENGTH) return;
    setTouched(true, true);
  }, [setTouched, titleValue?.length]);

  return (
    <>
      {/* All images */}
      <EtsyProductImages />

      {/* Start from */}

      <TitleForBlock
        title={"Listing details"}
        description={
          "Tell the world all about your item and why they’ll love it."
        }
        classNameWrapper={"section-wrapper"}
      />

      <WrapperElementBlock
        label={"Title*"}
        description={
          "Include keywords that buyers would use to search for your item."
        }
      >
        <FormikInput
          id="title"
          name="title"
          label={"Title"}
          maxLengthForCounter={MAX_ETSY_TITLE_LENGTH}
          maxLength={MAX_TITLE_LENGTH}
          min={MIN_TITLE_LENGTH}
          containerClassName="mb-3"
          placeholder="Write your title"
          type="text"
          isShowLengthDescription
          isHideLabel
        />
      </WrapperElementBlock>

      <WrapperElementBlock
        label={"About the listings*"}
        description={"Choose next option to sell your item on Etsy"}
      >
        <Row>
          <Col>
            <FormikSingleSelect
              name="whoMadeIt"
              placeholder="Who made it?"
              label="Who made it?"
              options={ETSY_WHO_MADE_IT_OPTIONS}
              isHideLabel
            />
          </Col>
          <Col>
            <FormikSingleSelect
              name="whatIsIt"
              placeholder="What is it?"
              label="What is it?"
              options={ETSY_WHAT_IS_IT_OPTIONS}
              isHideLabel
            />
          </Col>
          <Col>
            <FormikSingleSelect
              name="whenWasItMade"
              placeholder="When was it made?"
              label="When was it made?"
              options={ETSY_WHEN_WAS_IT_MADE_OPTIONS}
              isHideLabel
            />
          </Col>
        </Row>
      </WrapperElementBlock>

      <Categories
        categoryId={values.categoryId}
        suggested={suggestedFields.categoryName}
        isLoading={isLoading}
      />

      <WrapperElementBlock label={"Material"}>
        <FormikMultiSelect
          name="materials"
          allowCreate
          placeholder="Add materials"
          options={[]}
          description="Separate materials with coma. Use up to 13 materials, components or ingredients."
          label={"Material"}
          isHideLabel
        />
      </WrapperElementBlock>

      <WrapperElementBlock
        label={"Primary color"}
        description={
          "Primary and secondary color attributes are interchangeable so you can show shoppers that your item is multicolored."
        }
      >
        <FormPrimaryColor
          colors={ETSY_PRIMARY_COLORS}
          placeholder="Choose primary color"
          isHideLabel
        />
      </WrapperElementBlock>

      <WrapperElementBlock
        label={"Renewal options*"}
        description={
          "Each renewal lasts for four months or until the listing sells out."
        }
      >
        <Col md={6}>
          <Row>
            <Col>
              <FormikCheckbox
                name="renewalOptionsAutomatic"
                id="renewalOptionsAutomatic"
                text="Automatic"
              />
            </Col>
            <Col>
              <FormikCheckbox
                name="renewalOptionsManual"
                id="renewalOptionsManual"
                text="Manual"
              />
            </Col>
          </Row>
        </Col>
      </WrapperElementBlock>

      <WrapperElementBlock
        label={"Description*"}
        description={
          "Start with a brief overview that describes your item’s finest features. Shoppers will only see the first few lines of your description at first, so make it count!"
        }
      >
        <FormikTextarea
          id={"desc"}
          name={"description"}
          label={"Description"}
          placeholder={"Enter item description"}
          type="text"
          maxLengthForCounter={MAX_DESCRIPTION_LENGTH}
          maxLength={MAX_DESCRIPTION_LENGTH}
          isShowLengthDescription
          isHideLabel
          rows={COUNT_OF_ROWS_STR}
          className={"mb-0"}
        />
      </WrapperElementBlock>

      <WrapperElementBlock
        label={"Tags"}
        description={
          "What words might someone use to search for your listings? "
        }
      >
        <FormTags
          isHideLabel
          description={
            "Separate tags with a comma ',' Use up to 13 tags words someone might use to search"
          }
        />
      </WrapperElementBlock>

      <TitleForBlock
        title={"Inventory and pricing"}
        classNameWrapper={"section-wrapper"}
      />

      <WrapperElementBlock
        label={"Price*"}
        description={
          "Etsy charges $0.20 to publish a listing and a 5% transaction fee on the sale. "
        }
      >
        <Col md="6">
          <FormikGroupAddon
            label="Price"
            name="price"
            groupText="$"
            inputClassName="priceInputWrapper"
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            isHideLabel
            placeholder={"00.00"}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
      </WrapperElementBlock>

      <WrapperElementBlock label={"Cost of item"}>
        <Col md="6">
          <FormikGroupAddon
            label="Cost of item"
            name="costOfItem"
            groupText="$"
            inputClassName="priceInputWrapper"
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            isHideLabel
            description="For internal use only"
            placeholder={"00.00"}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
      </WrapperElementBlock>

      <WrapperElementBlock label={"SKU"}>
        <Col md={6}>
          <FormikInput
            id="sku"
            name="sku"
            placeholder="Enter SKU number"
            type="text"
            maxLength={MAX_LENGTH_SKU}
          />
        </Col>
      </WrapperElementBlock>

      <TitleForBlock
        title={"Shipping"}
        description={
          "Set clear and realistic shipping expectations for shoppers by providing accurate processing time."
        }
        classNameWrapper={"section-wrapper"}
      />

      <WrapperElementBlock label={"Shipping profile*"}>
        <ShippingTemplate marketplaceData={marketplaceData} />
      </WrapperElementBlock>

      <WrapperElementBlock label={"Package weight*"}>
        <Row>
          <Col>
            <FormikGroupAddon
              label="weightLb"
              name="weightLb"
              groupText="lb"
              placeholder="0"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              step={STEP_BY_HUNDREDTH}
              isHideLabel
            />
          </Col>
          <Col>
            <FormikGroupAddon
              label="weightOz"
              name="weightOz"
              groupText="oz"
              placeholder="0"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              step={STEP_BY_HUNDREDTH}
              isHideLabel
            />
          </Col>
        </Row>
      </WrapperElementBlock>

      <WrapperElementBlock
        label={getEtsyDimensionsLabel(values.shippingTemplateId)}
        classWrapper={"mb-4"}
      >
        <Row>
          <Col>
            <FormikGroupAddon
              label="Length"
              placeholder="Length"
              name="depth"
              groupText="in"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              step={STEP_BY_HUNDREDTH}
              isHideLabel
            />
          </Col>
          <Col>
            <FormikGroupAddon
              label="Width"
              placeholder="Width"
              name="width"
              groupText="in"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              step={STEP_BY_HUNDREDTH}
              isHideLabel
            />
          </Col>
          <Col>
            <FormikGroupAddon
              label="Height"
              placeholder="Height"
              name="height"
              groupText="in"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              step={STEP_BY_HUNDREDTH}
              isHideLabel
            />
          </Col>
        </Row>
      </WrapperElementBlock>

      <ListingUrl
        values={values}
        postedInfo={postedInfo}
        setFieldValue={setFieldValue}
        isDisabledCopyBtn={formRef?.current?.errors?.link}
        marketplaceType={MARKETPLACES.etsy}
      />
    </>
  );
};

export default DraftEtsyFormInner;
