/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../base/components/CustomButton";
import ReturnLabel from "../ReturnLabel";
import { getFormattedDate } from "../../../drafts/helpers/format";
import { MARKETPLACE_TYPE } from "../../../inventory/constants/importInventory";
import { getFormattedPrice } from "../../utils/getFormatedPrice";
import {
  RETURN_STATUSES,
  RETURN_STATUSES_LIST,
} from "../../constants/returns-constants";
import { SIZE_LABEL, SIZE_TEXT } from "./constants";
import "./index.scss";
import filterObjectFalsy from "../../../../base/helpers/filterObjectFalsy";
import { useService } from "../../../../base/hooks/useService";
import ProductReturnsService from "../../../../services/ProductReturnsService";
import { useHistory } from "react-router-dom";
import { RETURN_GROUP_LINKS } from "../../config";
import ToolTip from "../../../../base/components/ToolTip/ToolTip";
import { phrases } from "../../../../store/phrases";

const ReturnCardContent = (props) => {
  const {
    id,
    returnedAt,
    receivedAt,
    returnReason,
    receivedCondition,
    returnShippingCost,
    itemCost,
    shippingCost,
    soldAt,
    marketplaceType,
    description,
    notes,
    status,
    onRelist,
    isDisableRelist,
    title,
    shippingChargedToBuyer,
    priceSold,
    updateTabs,
    productId,
    marketplaceFees,
    returnMarketplaceFees,
    miscRefund,
    shippingCostRefund,
  } = props;

  /**
   * @type {ProductReturnsService}
   */
  const productReturnsService = useService(ProductReturnsService);
  const history = useHistory();

  const handleUpdate = useCallback(
    (oldData, newData) => {
      if (newData.status === oldData.status) return;
      updateTabs((prevState) => {
        prevState[oldData.status - 1].count =
          prevState[oldData.status - 1].count - 1;

        return [...prevState];
      });
    },
    [updateTabs]
  );

  const onComplete = (id, value) => {
    productReturnsService
      .updateReturnProduct(id, filterObjectFalsy(value))
      .then(() => {
        handleUpdate(props, value);
        history.push(RETURN_GROUP_LINKS.COMPLETED);
      })
      .catch((e) => Bugsnag.notify(e));
  };

  const onClick = useMemo(() => {
    const methodsMap = {
      [RETURN_STATUSES.IN_PROGRESS]: () =>
        onComplete(id, {
          title,
          description,
          itemCost,
          shippingCost,
          soldAt,
          priceSold,
          shippingChargedToBuyer,
          marketplaceType,
          returnedAt,
          receivedAt,
          returnReason,
          receivedCondition,
          returnShippingCost,
          notes,
          status: RETURN_STATUSES.RETURNED,
          marketplaceFees,
          returnMarketplaceFees,
          miscRefund,
          shippingCostRefund,
        }),
      [RETURN_STATUSES.RETURNED]: () => onRelist(id),
    };

    return methodsMap[status];
  }, [onRelist, props]);

  return (
    <div className="mt-4">
      <Row className="mb-2">
        <Col md={6}>
          <ReturnLabel label="Description" text={description} />
          <ReturnLabel
            id="text-primary-green"
            label="Price sold"
            text={getFormattedPrice(priceSold)}
          />
          <ReturnLabel
            id="text-primary-green"
            label="Cost of good"
            text={getFormattedPrice(itemCost)}
          />
          <ReturnLabel
            label={phrases.shippingCost}
            text={getFormattedPrice(shippingCost)}
          />
          <ReturnLabel
            label={phrases.shippingRefundCost}
            text={getFormattedPrice(shippingCostRefund)}
            toolTip={<ToolTip text={phrases.shippingCostRefundTooltip} />}
          />
          <ReturnLabel
            label={phrases.shippingChargedToBuyer}
            text={getFormattedPrice(shippingChargedToBuyer)}
          />
          <ReturnLabel
            label={phrases.returnShippingCost}
            text={getFormattedPrice(returnShippingCost)}
            toolTip={<ToolTip text={phrases.returnShippingCostTooltip} />}
          />
          <ReturnLabel
            label={phrases.miscRefund}
            text={getFormattedPrice(miscRefund)}
            toolTip={<ToolTip text={phrases.miscRefundTooltip} />}
          />
        </Col>
        <Col md={6}>
          <ReturnLabel label="Status" text={RETURN_STATUSES_LIST[status]} />
          <ReturnLabel
            label="Marketplace"
            text={MARKETPLACE_TYPE[marketplaceType]}
          />
          <ReturnLabel label="Date sold" text={getFormattedDate(soldAt)} />
          <ReturnLabel
            label="Return date"
            text={getFormattedDate(returnedAt)}
          />
          <ReturnLabel label="Return reason" text={returnReason} />
          <ReturnLabel
            label="Received date"
            text={getFormattedDate(receivedAt)}
          />
          <ReturnLabel label="Received condition" text={receivedCondition} />
          <ReturnLabel
            label="Marketplace fees"
            text={getFormattedPrice(marketplaceFees)}
          />
          <ReturnLabel
            label="Marketplace fees credit"
            text={getFormattedPrice(returnMarketplaceFees)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <ReturnLabel
            label="Additional notes"
            text={notes}
            sizeLabel={SIZE_LABEL}
            sizeText={SIZE_TEXT}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end align-item-center">
        {(productId || status === RETURN_STATUSES.IN_PROGRESS) && (
          <CustomButton
            className="filled-sm"
            onClick={onClick}
            disabled={isDisableRelist}
          >
            {status === RETURN_STATUSES.IN_PROGRESS ? "Complete" : "Relist"}
          </CustomButton>
        )}
      </div>
    </div>
  );
};

ReturnCardContent.propTypes = {
  id: PropTypes.number,
  returnedAt: PropTypes.string,
  receivedAt: PropTypes.string,
  returnReason: PropTypes.string,
  receivedCondition: PropTypes.string,
  returnShippingCost: PropTypes.number,
  itemCost: PropTypes.number,
  shippingCost: PropTypes.number,
  priceSold: PropTypes.number,
  shippingChargedToBuyer: PropTypes.number,
  soldAt: PropTypes.string,
  marketplaceType: PropTypes.number,
  description: PropTypes.string,
  notes: PropTypes.string,
  status: PropTypes.number,
  onRelist: PropTypes.func,
  isDisableRelist: PropTypes.bool,
};

export default ReturnCardContent;
