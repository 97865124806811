import { useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import S3Service from "../../../services/S3Service";
import { fileTypes } from "../../../base/constants/fileTyles";

const useUploadImagesToS3 = () => {
  /**
   * @type {S3Service}
   */
  const s3 = useService(S3Service);

  return useCallback(
    async (files, savedFiles = []) => {
      return s3.uploadImage(files);
    },
    [s3]
  );
};

export default useUploadImagesToS3;
