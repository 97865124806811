/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import Bugsnag from "@bugsnag/js";
import styled from "styled-components";

import ToasterService from "../../../services/ToastService";
import { validateFile } from "./utils/validateFile";
import { useService } from "../../hooks/useService";
import "./index.scss";
import CustomButton from "../CustomButton";
import { ERROR_MESSAGE } from "./constants/dropzoneConstants";

const CenteredText = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DropZoneCard = ({
  onDrop,
  errorMessage,
  isDropContainer = true,
  className,
  children = ERROR_MESSAGE,
  isLoading = false,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      className={classnames(
        { [className]: !!className },
        { "dropzone-file": !className },
        { "drag-active": isDragActive }
      )}
    >
      {isLoading ? (
        <CenteredText>Loading</CenteredText>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} className={className} />
          {isDropContainer && (
            <div className="upload-container">
              <i className="bx bxs-cloud-upload cloud" />
              <p
                className={classnames("upload-container__text", {
                  "error-message": errorMessage,
                })}
              >
                {errorMessage || children}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const DropzoneFile = ({
  onValidate = validateFile,
  onReceiveFile,
  className,
  isDropContainer = true,
  onCloseModal,
  onSave,
}) => {
  const toastr = useService(ToasterService);
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptedFile = (file) => {
    try {
      const error = onValidate(file);

      if (error) {
        setErrorMessage(`${file.name}: ${error}`);
        const errorCopy = errorMessages.slice();
        errorCopy.push(`${file.name}: ${error}`);
        setErrorMessages(errorCopy);
      } else {
        return file;
      }
    } catch (error) {
      Bugsnag.notify(error);

      const { message } = error;
      toastr.error(message);
      return;
    }

    setIsDisabledSave(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles.length) return;

    setErrorMessages([]);

    const filteredFiles = acceptedFiles.filter((file) =>
      handleAcceptedFile(file)
    );

    setFileList(filteredFiles);
  }, []);

  const handleErrors = () => {
    if (errorMessages.length > 0) {
      errorMessages.forEach((error) => {
        toastr.error(error);
      });
    }
  };

  useEffect(() => {
    if (fileList.length === 0) return;

    setIsLoading(true);
    onReceiveFile(fileList);
    onSave();
  }, [JSON.stringify(fileList)]);

  useEffect(() => {
    handleErrors();
  }, [errorMessages]);

  useEffect(() => {
    return () => {
      setFileList([]);
    };
  }, []);

  return (
    <>
      <DropZoneCard
        onDrop={onDrop}
        errorMessage={errorMessage}
        isDropContainer={isDropContainer}
        className={className}
        isLoading={isLoading}
      />
      <div className="modal-buttons-wrapper">
        <CustomButton className="outline-primary" onClick={onCloseModal}>
          Cancel
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onSave}
          disabled={isDisabledSave || isLoading}
        >
          Save
        </CustomButton>
      </div>
    </>
  );
};

DropzoneFile.propTypes = {
  onValidate: PropTypes.func,
  onReceiveFile: PropTypes.func,
  className: PropTypes.string,
  isDropContainer: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default DropzoneFile;
